html,
body {
  height: 100vh;
  font-size: 16px;
}
body {
  background-position: center bottom;
  background-size: cover;
  background: url("/images/bg.jpg") no-repeat center bottom fixed;
  background-size: cover;
}
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}

.ant-form-item-control-wrapper {
  padding: 0 12px !important;
}

.ant-form-item-label {
  white-space: normal !important;
}

.ant-descriptions-item-content {
  min-width: 200px;
}

.ant-table-thead .ant-table-header-column {
  min-width: 70px !important;
}

.ant-form-item .ant-form-item-label-left {
  line-height: 30px;
}

@media print {
  .ant-layout {
    height: 100% !important;
    overflow: auto !important;
  }

  .ant-layout-header {
    display: none !important;
  }
}
